import { Box, Container, Show } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Navigation from "../components/navigation";
import ScrollToTop from "../components/ScrollToTop";
import StartForm from "../components/StartForm/StartForm";
const HomeCircleImageDecoration = dynamic(() => import("@/components/HomeComponents/HomeCircleImageDecoration").then(mod => mod.HomeCircleImageDecoration), {
  ssr: false
});
const AlgoHome = dynamic(() => import("@/components/HomeComponents/AlgoHome"));
const PromoRessources = dynamic(() => import("@/components/Ressources/promoRessources"));
const HowTo = dynamic(() => import("@/components/HowTo/HowTo"));
const Footer = dynamic(() => import("@/components/footer"));
const AmeliorerLBA = dynamic(() => import("@/components/HomeComponents/AmeliorerLBA"));
const blockCssProperties = {
  position: "relative",
  borderRadius: "10px",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "1310px"
};
const Home = () => <Box data-sentry-element="Box" data-sentry-component="Home" data-sentry-source-file="index.tsx">
    <ScrollToTop data-sentry-element="ScrollToTop" data-sentry-source-file="index.tsx" />
    <Navigation data-sentry-element="Navigation" data-sentry-source-file="index.tsx" />
    <Box as="main" data-sentry-element="Box" data-sentry-source-file="index.tsx">
      <Box background="beige" sx={blockCssProperties} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Show above="lg" data-sentry-element="Show" data-sentry-source-file="index.tsx">
          <HomeCircleImageDecoration data-sentry-element="HomeCircleImageDecoration" data-sentry-source-file="index.tsx" />
        </Show>
        <Container variant="responsiveContainer" pt={{
        base: 3,
        sm: 12
      }} pb={12} position="relative" data-sentry-element="Container" data-sentry-source-file="index.tsx">
          <StartForm data-sentry-element="StartForm" data-sentry-source-file="index.tsx" />
        </Container>
        <HowTo data-sentry-element="HowTo" data-sentry-source-file="index.tsx" />
      </Box>

      <Box sx={blockCssProperties} py={12} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <AlgoHome data-sentry-element="AlgoHome" data-sentry-source-file="index.tsx" />
      </Box>

      <Box sx={blockCssProperties} pb={12} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <PromoRessources target="candidat" data-sentry-element="PromoRessources" data-sentry-source-file="index.tsx" />
      </Box>

      <Box sx={blockCssProperties} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <AmeliorerLBA data-sentry-element="AmeliorerLBA" data-sentry-source-file="index.tsx" />
      </Box>
    </Box>

    <Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
  </Box>;
export default Home;